.body {
  padding: 0px;
  margin: 0px;
  font-family: 'Noto Sans';
  width: 100%; /* Set width to 100% of viewport width */
  overflow-x:hidden;

}
.responsive-nav{
  position: fixed; /* Sticky/fixed navbar */
 
  transition: 0.4s; /* Adds a transition effect when the padding is decreased */
  width: 100%;
  top: 0;
  z-index: 99;
}
/* responsive font size */
h1{
  font-size: clamp(12px,4vw,32px) !important;
}

h2{
  font-size: clamp(18px,4vw,28px) !important;
}

Button{
  font-size: clamp(4px,4vw,10px);
}

p{
  font-size: clamp(8px,3vw,16px);
}

.hero{
  padding-left: clamp(30px, 4vw,100px) !important;
  padding-right: clamp(50px, 4vw,100px) !important;
}


Nav.Link :hover{
  color: #85DD52;
  font-weight: bold;
}
.navbar a{
  text-decoration: none;
  color: black;
  
}
.Link .active{
  color: #85DD52;
}
.active.active-link {
  color: #85DD52;
}
.broucherBtn :hover{
  color: yellowgreen;
}
.active-link {
  color: #85DD52;
  font-weight: bold;
  
}
.navbar a :hover{
  text-decoration: none;
  color: #85DD52;
  font-weight: bold;
}


.navbar Link a {
  color: black;
}

.nav-item :hover{
  padding: 2px;
  border: #85DD52 solid 1.5px;
  
  border-radius: 5px;
}

.invite-head h5{
  font-size: clamp(10px,4vw,20px);
}
.invite-head p{
  font-size: clamp(7px,3vw,15px);
}
.customfooter{
  background-color: #85DD52;
}

.image-gallery-slide {
transition: all 0.5s ease-in-out;
  }
  
  .image-gallery-thumbnail {
    border: 0.5px solid #ddd;
  }
  
  .image-gallery-icon {
    color: #333;
  }
  .wrapper img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
}
.overlay img {
  display: block;
  max-width: 70%;
  max-height: 90%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
}